<template>
	<transition appear name="fade">
		<slot></slot>
	</transition>
</template>

<script>
	export default {};
</script>

<style>
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s;
	}
	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}
</style>