<script>
export default {
  data: () => ({
    sticky: false,
		stickyClasses: [],
		noTransition: false,

		isOpen: false,
		mobileSubnavId: null,
		mobileExpandedId: null,

		scrollDirection: 'down',

		menuReady: false
  }),
	methods: {
		open() {
			// prep transition
			this.menuReady = true

			this.$nextTick(() => this.isOpen = true)
		},
		close() {
			this.isOpen = false
		},
		toggle() {
			// prep transition
			this.menuReady = true

			const isOpen = !this.isOpen
			this.$nextTick(() => this.isOpen = isOpen)
		},

		openMobileSubnav(id) {
			this.mobileSubnavId = id
		},
		closeMobileSubnav() {
			this.mobileSubnavId = null
		},

		toggleSubnavExpansion(id) {
			if(this.mobileExpandedId === id) {
				this.mobileExpandedId = null
			} else {
				this.mobileExpandedId = id
			}
		}
	},
	watch: {
		mobileSubnavId(mobileSubnavId, prev) {
			// closing
			if(mobileSubnavId === null) {
				// manage scrolling
				document.querySelector('.mobile-nav').style.overflowY = null

				// manage focus
				const openTrigger = `#open-mobile-subnav-${prev}`
				document.querySelector(openTrigger).focus()
			} else { // opening
				// manage scrolling
				document.querySelector('.mobile-nav').style.overflowY = 'hidden'

				// manage focus
				setTimeout(() => {
					console.log(document.querySelector('#active-subnav-heading'))
					document.querySelector('#active-subnav-heading').focus()
				}, 450)
			}
		},
		isOpen(open, prev) {
			if(open) {
				document.body.style.overflow = 'hidden'
			} else {
				document.body.style.overflow = null
			}
		},
		sticky(sticky) {
			if(sticky) {
				this.stickyClasses = ['-translate-y-full']

				setTimeout(() => {
					this.stickyClasses = ['sticky', '-translate-y-full', 'transition-transform']
				}, 50)

				setTimeout(() => {
					this.stickyClasses = ['sticky', 'transition-transform']
				}, 100)

			} else {
				if(this.noTransition) {
					this.stickyClasses = []
				} else {
					this.stickyClasses = ['sticky', '-translate-y-full', 'transition-transform', 'duration-300']

					setTimeout(() => {
						this.stickyClasses = []
					}, 350)
				}
			}
		},
	},
	mounted() {
		let scrollPosition = window.scrollY
		let lowestScrollPosition = window.scrollY

		window.addEventListener('scroll', () => {
			// console.log('🍎')
			const newScrollPosition = window.scrollY
			// console.log({ newScrollPosition })

			// calculate scroll direction
			const direction = newScrollPosition > scrollPosition ? 'down' : 'up'
			this.scrollDirection = direction

			scrollPosition = newScrollPosition

			if (newScrollPosition < 200) {
				this.noTransition = true
			} else {
				this.noTransition = false
			}

			if (direction === 'down' || newScrollPosition === 0) {
				lowestScrollPosition = newScrollPosition
				this.sticky = false
			} else if (direction === 'up' && newScrollPosition < (lowestScrollPosition - 50)) {
				lowestScrollPosition = newScrollPosition

				if(newScrollPosition > 700) {
					this.sticky = true
				}
			}
		})
	},
  render() {
    return this.$slots.default({
      sticky: this.sticky,
			stickyClasses: this.stickyClasses,
			noTransition: this.noTransition,

			isOpen: this.isOpen,
			open: this.open,
			close: this.close,
			toggle: this.toggle,

			mobileSubnavId: this.mobileSubnavId,
			openMobileSubnav: this.openMobileSubnav,
			closeMobileSubnav: this.closeMobileSubnav,

			mobileExpandedId: this.mobileExpandedId,
			toggleSubnavExpansion: this.toggleSubnavExpansion,

			scrollDirection: this.scrollDirection,

			menuReady: this.menuReady
    })
  }
}
</script>

<style>
  :root {
    --primary-nav-height: 86px;
		--pre-nav-height: 81px;
  }

	@screen lg {
		:root {
			--primary-nav-height: 120px;
			--pre-nav-height: 54px;
		}
	}

	.header {
		margin-top: calc(-1 * var(--primary-nav-height));
		padding-top: var(--primary-nav-height);
	}

	.pre-nav-bar {
		height: var(--pre-nav-height);
	}

  .primary-nav-placeholder {
    height: var(--primary-nav-height);
  }

  .primary-nav {
    /* .sticky class will be applied by Vue */
    top: 0;
    height: var(--primary-nav-height);
	margin-bottom: calc(var(--primary-nav-height) * -1);
	transition: 400ms background;
  }

  .primary-nav-subnav {
		position: relative;
		@apply py-s8;
    width: 340px;
  }

	/* sticky */

	.primary-nav.sticky {
		background: theme('colors.gray.900');
	}

	/* dropdowns */

	/* dropdowns: hide/show dropdowns */
	.primary-nav-level-2-dropdown,
	.primary-nav-level-3-dropdown {
		visibility: hidden;
	}

	.primary-nav-level-1:hover .primary-nav-level-2-dropdown,
	.primary-nav-level-2:hover .primary-nav-level-3-dropdown {
		visibility: visible;
	}

	/* dropdowns: level 2 */
	.primary-nav-level-2-dropdown {
		z-index: 0;
		opacity: 0;
		transition: opacity 300ms 100ms, visibility 300ms 100ms;
	}

	.primary-nav-level-1:hover .primary-nav-level-2-dropdown {
		opacity: 1;
		transition: opacity 300ms;
	}

	/* dropdowns: level 3 */
	.primary-nav-level-3-dropdown {
		z-index: -1;
		transform: translateX(-50%);
		opacity: 0;
		transition: opacity 300ms 100ms, transform 450ms 100ms, visibility 0ms 550ms;
	}

	.primary-nav-level-2:hover .primary-nav-level-3-dropdown {
		opacity: 1;
		transform: translateX(0);

		transition: opacity 300ms, transform 450ms;
	}

	/* indicator arrow for level 3 nav */

	.primary-nav-level-3-arrow {
		transform: translateX(-100%);
		transition: 300ms transform;
	}

	.primary-nav-level-2:hover .primary-nav-level-3-arrow {
		transform: translateX(0);
		transition: 300ms transform 250ms;
	}

	.cutout {
		position: absolute;
		inset: 0;

		--offset: 18px;
		--hypotenuse: 25.456px;

		clip-path: polygon(0 0, 100% 0, 100% 100%, 18px 100%, 0 calc(100% - 18px));
		background-color: currentColor;

		transition: 400ms background-color;
	}

	.cutout-outlined {
		background-color: transparent;
	}

	.group:hover .cutout {
		background-color: currentColor;
	}

	.cutout-outlined .cutout-left {
		position: absolute;
		top: 0;
		left: 0;
		bottom: var(--offset);
		border-left: 2px solid currentColor;
	}

	.cutout-outlined .cutout-bottom {
		position: absolute;
		bottom: 0;
		left: var(--offset);
		right: 0;
		border-bottom: 2px solid currentColor;
	}

	.cutout-outlined .cutout-angle {
		position: absolute;
		bottom: 0;
		left: var(--offset);
		height: var(--hypotenuse);
		border-left: 2px solid currentColor;

		transform: rotate(-45deg);
		transform-origin: bottom left;
	}

	.primary-nav-light {
		--eyebrow-color: theme('colors.red.500');
		--eyebrow-color-active: theme('colors.red.100');
	}

	.primary-nav-dark {
		--eyebrow-color: theme('colors.red.500');
		--eyebrow-color-active: theme('colors.red.700');
		background: linear-gradient(180deg, #212122 31.32%, rgba(33, 33, 34, 0.6319) 52.02%, rgba(33, 33, 34, 0) 92.99%);
	}

	/* eyebrow styles */

	.primary-nav-item {
		position: relative;
	}

	.primary-nav-item::before {
		content: '';
		position: absolute;
		left: -2px;
		bottom: 100%;
		height: 14px;
		width: calc(100%);
		border-style: solid;
		border-top-width: 2px;
		border-left-width: 3px;

		border-color: transparent;
		transition: 300ms 100ms border-color;

		transform: skew(-45deg);
	}

	.primary-nav-item-active::before {
		border-color: var(--eyebrow-color-active);
	}

	.primary-nav-item:hover::before {
		border-color: var(--eyebrow-color);
	}

	/* mobile */

	.mobile-nav {
		--joint-nav-height: calc(var(--pre-nav-height) + var(--primary-nav-height));

		position: absolute;
		top: 100%;
		padding-bottom: calc(theme('spacing.s8') + var(--joint-nav-height));
		height: 100vh;
		width: 100%;
	}

	.mobile-nav-level-1-button {
		position: relative;
	}

	.mobile-nav-level-1-button::before {
		content: '';
		position: absolute;
		left: 7px;
		top: 0;
		height: 14px;
		width: calc(100% - 12px);
		border-style: solid;
		border-top-width: 2px;
		border-left-width: 3px;
		border-color: theme('colors.gray.100');

		transform: skew(-45deg);
	}

	.mobile-nav-level-2-panel {
		height: calc(100vh - var(--primary-nav-height) - var(--pre-nav-height) - theme('spacing.s8'));
	}

</style>
