<template>
	<fancy-box :color="color" :notch="notch" :type="type">
		<template #image>
			<slot name="image"></slot>
		</template>
		<template #content>
			<div v-for="section in sections" :key="section.title" class="content-section mb-s4 inline-block w-full">
				<div class="inline-flex w-full space-x-s3 my-s2">
					<span class="ui section-title opacity-90">{{ section.title }}</span>
					<div class="section-line h-[2px] flex-grow mt-[9px] opacity-20"></div>
				</div>
				<p class="section-text inline-block w-full before:inline-block after:inline-block" v-html="section.content"></p>
			</div>
			<slot name="button" />
		</template>
		<template #tags v-if="tags && tags.length">
			<div class="tags-container container w-full px-s8 py-s9 ">
				<div class="content">
					<h3 class="ui faux- tags-title mb-s4">
						Services
					</h3>
					<div>
						<span v-for="tag in tags" :key="tag" class="ui-xs faux- tag relative inline-block whitespace-nowrap mr-[11px] mb-s5 " v-html="tag">
						</span>
					</div>
				</div>
			</div>
		</template>
	</fancy-box>
</template>

<script>
	export default {
		props: {
			color: null,
			notch: null,
			tags: Array,
			sections: Array,
			button: Object
		},
		data() {
			return {
				type: 'sidebar-box'
			}
		}
	}
</script>

<style scoped>
	#app .content-section:last-of-type:not(:last-child){
		@apply mb-s8;
	}
	#app .content-section:not(:last-child){
		@apply mb-0;
	}
	.section-title{
		color: var(--section-title-color);
	}
	.section-line{
		background-color: var(--section-title-color);
	}
	.section-text{
		color: var(--text-color)
	}
	.tags-container{
		background-color: var(--tags-bg-color)
	}
	.tags-title{
		color: var(--tags-title-color);
	}
	.tag{
		color: var(--text-color);
		@apply opacity-60;

	}
	.tag::after{
		content:'';
		background-color: var(--text-color);
		height:2px;
		bottom:-8px;
		@apply absolute;
		@apply w-full;
		@apply left-0;
		@apply mt-0;
		@apply opacity-20;
	}
</style>

<style lang="postcss">
	.sidebar-box-container {
		margin-top: calc(theme('spacing.s13') * -1 - 100px);

		@media (min-width: 768px) {
			top: calc(var(--primary-nav-height) + 20px);
			margin-top: calc((theme('spacing.s13') * -1) - 340px);
			margin-bottom: calc((theme('spacing.s14') * -1) - 100px);
		}
	}
</style>
