<script>
import { colors } from '../../../config/build/carbon.mjs';

const eyebrowColors = {
	red: colors['red'][500],
	teal: colors['teal'][500],
	green: colors['green'][500],
	gray: colors['gray'][100],
	darkGray: colors['gray'][800],
	white: colors['gray'][50],
}
const positions = {
	left: {},
	right: {},
	bottom: {},
	top: {},
}

export default {
	props: {
		position: {
			type: String,
			default: 'right',
			validator: (value) => Object.keys(positions).includes(value)
		},
		color: {
			type: String,
			default: 'gray',
			validator: (value) => Object.keys(eyebrowColors).includes(value)
		},
		size: {
			type: String,
			default: 'base',
			validator: (value) => ['base', 'sm'].includes(value)
		}
	},
	computed: {
		colorCSS() {
			const { color } = this
			const theme = eyebrowColors[color]
			
			return {
				'--eyebrow-color': theme,
			}
	
		},
	}
}
</script>

<template>
	<div
		:class="`${(position == 'left' || position == 'right' ? 'eyebrow' : 'eyebrow-vertical')} ${('eyebrow--'+position)}`"
		:style="{ ...colorCSS }"
	>
		<slot />
	</div>
</template>

<style scoped>
	/* eyebrow styles */

	.eyebrow, .eyebrow-vertical {
		position: relative;
	}

	.eyebrow::before {
		content: '';
		position: absolute;
		top: 0;
		height: 26px;
		width: calc(100% - 22px);
		
		border-style: solid;
		border-top-width: 3px;
		border-color: var(--eyebrow-color);
	}
	.eyebrow--right::before{
		right: 10px;
		border-right-width: 4px;
		transform: skew(45deg);
	}
	.eyebrow--left::before{
		left: 16px;
		border-left-width: 4px;
		transform: skew(-45deg);
	}



	.eyebrow-vertical::before {
		content: '';
		position: absolute;
		left: 0;
		height: calc(100% - 22px);
		width: 20px;
		
		border-style: solid;
		border-right-width: 3px;
		border-color: var(--eyebrow-color);
	}

	.eyebrow--bottom::before{
		bottom: 10px;
		border-bottom-width: 4px;
		transform: skewY(45deg);
	}
	.eyebrow--top::before{
		top: 16px;
		border-top-width: 4px;
		transform: skewY(45deg);
	}
</style>
