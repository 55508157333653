<template>
	<div class="relative flex to-lg:flex-col lg:min-h-[600px] bg-red-500">
		<img class="absolute top-[-70px] left-[36px] to-lg:w-[70px] to-lg:top-[-30px] to-lg:left-[14px] mix-blend-multiply z-10" src="@assets/test-images/quote mark.svg" />
		<div class="flex-1 flex flex-col">
			<div class="flex-grow px-s8 lg:px-s11 py-s9 lg:py-s12">
				<!-- Quote -->
				<p class="faux-p-lg font-semibold lg:font-bold text-white">{{ quote }}</p>

				<!-- Mobile Bio -->
				<div class="flex items-center lg:hidden mt-s8">
					<img v-if="image" :src="image" :alt="imageTitle" class="rounded-full w-[60px] h-[60px] mr-s5" />
					<icon v-else name="arrowhead-heavy-right" size="tiny" class="mr-s6 opacity-40 mt-[-4px]"></icon>

					<div>
						<h6 class="text-red-50">{{ name }}</h6>
						<p v-if="title" class="text-red-100 faux-p-sm mt-s4">{{ title }}</p>
					</div>
				</div>
			</div>

			<div class="relative to-lg:mt-s9 bg-red-600 px-s8 lg:px-s11 py-s8">
				<div class="bg-red-600 w-[100px] lg:w-[138px] h-s9 absolute -top-s9 left-0 z-10 flex items-center">
					<p class="ui faux- text-red-900 relative right-[-36px] text-right flex-1">Fun Fact</p>
				</div>

				<div class="rounded-notch bg-red-600 h-s9 w-[84px] absolute -top-s9 left-[100px] lg:left-[138px] "></div>

				<p class="faux-p-sm text-white">{{ fact }}</p>
			</div>
		</div>

		<div class="to-lg:hidden flex-1">
			<div class="absolute h-[72px] flex items-center bg-white right-0 bottom-[70px] pl-s3 pr-s7">
				<div class="rounded-point absolute top-0 left-[-30px] h-[72px] w-s7 bg-white">
				</div>
				<p class="text-red-500 faux-p-sm font-bold mr-s2">
					{{ name }}
				</p>
				<icon size="tiny" name="arrowhead-smaller-double-right" class="mt-0 mr-s2 text-gray-200">
				</icon>
				<p class="faux-p-sm">
					{{ title }}
				</p>
			</div>
			<img :src="image" :alt="imageTitle" class="w-full h-full object-cover" />
		</div>

		<svg width="0" height="0" xmlns="http://www.w3.org/2000/svg">
			<defs>
				<clipPath id="bgClip">
					<path   clip-rule="evenodd" d="M36 0L84 0L84 48L0 48C2.16219 44.2934 5.30113 39.0089 8.56642 33.5118C14.6978 23.1896 21.2747 12.1174 22.6667 9.33333C25.3333 4 30.6667 0 36 0Z" />
				</clipPath>
			</defs>
		</svg>
		<svg width="0" height="0" viewBox="0 0 31 73" fill="none" xmlns="http://www.w3.org/2000/svg">
			<defs>
				<clipPath id="nametagClip">
					<path d="M25.5964 5.385e-05C22.0238 8.94535e-05 18.7226 1.90607 16.9363 5.00005L1.88675 31.0666C0.100426 34.1606 0.100424 37.9726 1.88675 41.0666L16.9362 67.1331C18.7226 70.2271 22.0239 72.1331 25.5966 72.1331L31 72.133L31 0L25.5964 5.385e-05Z" fill="white"/>
				</clipPath>
			</defs>
		</svg>
	</div>

</template>
<script>
	export default {
		props:{
			name: String,
			title: String,
			quote: String,
			fact: String,
			image: String,
			imageTitle: String,
		}
	}
</script>

<style>
	.rounded-notch{
		clip-path: url('#bgClip');
		transform:scaleX(-1);

	}

	.rounded-point{
		clip-path: url('#nametagClip')
	}

</style>
