<template>
	<box :color="color" notch="right" type="callout" class = "md:flex max-w-[937px] mx-auto ">
		<slot>
			<img v-if="image" :src="image" :alt="imageTitle" class="w-full md:w-[50%] lg:w-[58%] object-cover"/>
			<div v-else class="icon-section w-full md:w-[152px] py-s8 md:py-0 px-s8 flex justify-center items-center">
				<icon :name="icon" size="lg" class = "icon"></icon>
			</div>
			<div class="w-full px-s8 py-s8 sm:px-s10 sm:py-s10">
				<p class="faux-h3" v-if="description">
					<slot name="content">{{description}}</slot>
				</p>
				<styled-link v-if="href"
					:href="href"
					:target="target"
					:color="(color == 'white'||color == 'lightGray') ? 'red' : color" 
					:dark="color != 'white'" 
					:inverted="inverted"
					iconRight="arrowhead-default-right" 
					class = "inline-flex cursor-pointer mt-s9"
					rel="noopener"
				>{{buttontext}}</styled-link>
			</div>
		</slot>
	</box>
</template>

<script>
	export default {
		props: {
			color: {
				type:String,
				default:'red'
			},
			image: String,
			imageTitle: String,
			icon: {
				type:String,
				default:'resource'
			},
			buttontext:{
				type:String,
				default:'download'
			},
			description:{
				type:String,
				default: null
			},
			href: {
				type: String,
				default: null
			},
			target: {
				type: String,
				default: null
			},
		},
		data() {
			return {
				inverted: (this.color == 'white'|| this.color == 'lightGray') ? false : true
			}
		},
		computed: {
			formattedQuote() {
				return this.quote.replace('<p>', '<p class="faux-p-lg quote font-bold">')
			},
			buttonColor() {
				return this.color == "white" ? "red" : this.color;
			}
		}
	}
</script>

<style scoped>

	.faux-h3 {
		color: var(--text-color) !important;
	}

	.icon-section{
		background-color: var(--tags-bg-color);
	}
	.icon{
		color:var(--tags-title-color);
	}

</style>