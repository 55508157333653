<script>
import { colors } from '../../../config/build/carbon.mjs';

const buttonColors = ['red', 'teal', 'green']

const allButtonColors = buttonColors.reduce((acc, color) => ({
	[color]: {
		base: colors[color]['500'],
		hover: colors[color]['600'],
		dark: colors[color]['800'],
		disabled: colors[color]['50']
	},
	...acc
}), {})

const buttonSizes = {
	base: { height: '48px', padding: '18px' },
	small: { height: '36px', padding: '12px' },
}

export default {
	props: {
		size: {
			type: String,
			default: 'base',
			validator: (value) => Object.keys(buttonSizes).includes(value)
		},
		color: {
			type: String,
			default: 'red',
			validator: (value) => buttonColors.includes(value)
		},
		dark: {
			type: Boolean,
			default: false
		},
		inverted: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		href: {
			type: String,
			default: null
		},
		as: {
			type: String,
			validator: (value) => ['a', 'button'].includes(value)
		},
		iconLeft: String,
		iconRight: String,
		redirectNotice: {
			type: String,
			default: null,
		}
	},
	data() {
		return {
			showRedirectModal: false,
			redirectCanceled: false,
		}
	},
	computed: {
		colorCSS() {
			const { color, dark, inverted, disabled } = this
			const theme = allButtonColors[color]

			// text color
			if(inverted) {
				if(disabled) {
					var text = 'rgba(255, 255, 255, 0.2)'
				} else if(dark) {
					var text = 'white'
				} else {
					var text = theme.base
				}
			} else {
				var text = 'white'
			}

			// hover text color
			if(inverted) {
				if(dark) {
					var textHover = theme.dark
				} else {
					var textHover = 'white'
				}
			} else {
				var textHover = 'white'
			}

			// BG
			if(inverted) {
				if(disabled) {
					var bg = 'rgba(255, 255, 255, 0.2)'
				} else if (dark) {
					var bg = theme.dark
				} else {
					var bg = 'white'
				}
			} else {
				if(disabled) {
					var bg = theme.disabled
				} else if (dark) {
					var bg = theme.dark
				} else {
					var bg = theme.base
				}
			}

			// BG Hover
			if(inverted) {
				if(disabled) {
					var bgHover = 'rgba(255, 255, 255, 0.3)'
				}
				else if (dark) {
					var bgHover = 'white'
				} else {
					var bgHover = theme.base
				}
			} else {
				if(dark) {
					var bgHover = theme.base
				} else {
					var bgHover = theme.hover
				}
			}

			return {
				'--text-color': text,
				'--text-color-hover': textHover,
				'--bg-color': bg,
				'--bg-color-hover': bgHover
			}
		},
		sizeCSS() {
			const { size } = this
			const sizeConfig = buttonSizes[size]
			return {
				'--height': sizeConfig['height'],
				'--padding-x': sizeConfig['padding']
			}
		},
		htmlElement() {
			if (this.redirectNotice) {
				return 'button'
			}
			const dynamic = this.href !== null ? 'a' : 'button'
			return this.as ? this.as : dynamic
		}
	},
	methods: {
		handleClick(event) {
			if (this.redirectNotice) {
				this.$refs.redirectNoticeModal.openModal();
				setTimeout(() => {
					if (!this.redirectCanceled) {
						window.location.href = this.href;
					}
					this.redirectCanceled = false
				}, 3000);
			}
			if(this.htmlElement === 'button') {
				this.$emit('click', event)
			}
		}
	}
}
</script>

<template>
	<component :is="htmlElement"
		@click="handleClick"
		class="btn"
		:disabled="disabled ? disabled : null"
		:style="{ ...colorCSS, ...sizeCSS }"
		:href="href"
	>

		<UiTextWithIcons
			:size="size"
			:iconLeft="iconLeft"
			:iconRight="iconRight"
		>
			<slot />
			<modal
				v-if="redirectNotice"
				ref="redirectNoticeModal"
				@modal-closed="redirectCanceled = true"
			>
				<template v-slot:header>
					<h2 class="faux-h5">{{ redirectNotice }}</h2>
				</template>
			</modal>
		</UiTextWithIcons>
	</component>
</template>

<style scoped>
	.btn {
		display: flex;
		justify-content: center;
		align-items: center;

		height: var(--height);
		padding-left: var(--padding-x);
		padding-right: var(--padding-x);

		clip-path: polygon(0 0, calc(100% - 12px) 0, 100% 12px, 100% 100%, 0 100%, 0 0);

		color: var(--text-color);
		background-color: var(--bg-color);

		transition: 400ms background-color;
	}

	.btn:not([disabled]):hover,
	.btn:not([disabled]):focus,
	.btn.hover {
		color: var(--text-color-hover);
		background-color: var(--bg-color-hover);
	}

	.btn:focus {
		outline: none;
	}
</style>
