<script>
export default {
	props: {
		progress: {
			type: Number,
			default: 100,
		},
	}
}
</script>

<template>
	<div class="progressbar h-[4px] bg-teal-100 w-full rounded-[4px]">
        <div 
            class="progressbar-int relative h-full bg-teal-500 rounded-[4px]"
            :style="{ width: progress + '%' }"
        ></div>
	</div>
</template>

<style scoped>
	.progressbar-int::after{
		content: '';
		position: absolute;
		top: -26px;
        right: -7px;
		
		width: 16px;
		height: 16px;
		
		background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' id='icon-definition'%3E%3Cpath d='M14 5.467a.25.25 0 00-.389-.208L8 9 2.389 5.26A.25.25 0 002 5.466v2.998a1 1 0 00.445.832L8 13l6-4V5.467z' fill='%2329A7B3'/%3E%3C/svg%3E");
	    background-size: 100%;
        background-repeat: no-repeat;
		
		
	}
</style>
