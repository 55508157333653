<template>
	<div>
		<slot />
	</div>
</template>

<script>
import smoothReflow from 'vue-smooth-reflow'
export default {
	mixins: [smoothReflow],
	mounted(){
		this.$smoothReflow()
	},
}
</script>
