<template>
  <a
    :href="entry.url"
    class="
      blog-link
      flex
      items-center
      w-full
      border-t-2 border-gray-100
      py-s8
      mt-0
      hover:border-red-500
      transition-all
    "
  >
    <div
      v-if="postPreview"
      class="relative w-s12 h-s12 mr-s5 sm:mr-s7 flex-shrink-0"
    >
      <img
        v-if="postPreview.type === 'image'"
        :src="postPreview.image"
        :alt="postPreview.imageTitle"
        class="w-full h-full object-cover"
      />
      <template v-else-if="postPreview.type === 'video'">
        <img
          :src="postPreview.image"
          :alt="postPreview.imageTitle"
          class="w-full h-full object-cover"
        />
        <div
          class="
            absolute
            inset-0
            bg-black bg-opacity-50
            flex
            items-center
            justify-center
          "
        >
          <icon
            name="play"
            size="sm"
            class="!w-icon-xs !h-icon-xs text-white"
          ></icon>
        </div>
      </template>
      <div
        v-if="postPreview.type === 'icon'"
        class="w-full h-full bg-red-500 flex items-center justify-center"
      >
        <icon
          :name="postPreview.iconName"
          size="md"
          class="text-red-700"
        ></icon>
      </div>
    </div>

    <div class="flex-grow">
      <div class="ui text-red-500 mb-s2">{{ entry.blog.postDate }}</div>
      <p class="faux-p-lg mt-s5 md:mt-s7 text-gray-900">{{ entry.title }}</p>
    </div>

    <icon
      name="arrowhead-right"
      size="xs"
      class="icon ml-s5 sm:ml-s7 flex-shrink-0 text-gray-500 transition-all"
    ></icon>
  </a>
</template>

<script>
export default {
  props: {
    entry: Object,
  },
  computed: {
    postPreview() {
      return this.entry.blog.postPreview;
    },
  },
};
</script>

<style>
#app .blog-link:hover .icon {
  @apply text-red-500;
}
</style>