<template>
	<div class="box w-full relative" :class="`notch-${notch}`" :style="colorCSS">
		<div v-if="type == 'default'" class="w-full px-s8 py-s10">
			<div class="content ">
				<slot></slot>
			</div>
		</div>
		<slot v-else></slot>
	</div>
</template>

<script>
	import { colors } from '../../../config/build/carbon.mjs';

	const boxColors = ['white', 'lightGray', 'red','green','teal']

	const coloredBoxColorSchemes = ['red','green','teal'].reduce((acc, color) => ({
		[color]:{
			base: colors[color][500],
			dark: colors[color][800],
			accent: colors[color][600],
			text: "#ffffff"
		},
		...acc
	}), {})

	const allBoxColorSchemes = {
		...coloredBoxColorSchemes,
		lightGray:{
			base: colors['gray'][50],
			dark: colors['gray'][100],
			accent: colors['red'][500],
			text: colors['gray'][900]
		},
		white:{
			base: "#ffffff",
			dark: colors['gray'][100],
			accent: colors['red'][500],
			text: colors['gray'][900]
		}
	}

    export default {
        name:'box',
        data: function () {
            return {
                loaded:false,
                eltHeight:null
            }
        },
        props: {
            color: null,
			notch: {
				type: String,
				default: 'none'
			},
			type: {
				type: String,
				default: 'default'
			}
        },
		computed: {
			colorCSS() {
				const theme = allBoxColorSchemes[this.color]
				const sectionTitle = this.color == 'white' || this.color == 'lightGray' ? theme['text'] : theme['dark']
				const tagsBg = this.color == 'white' || this.color == 'lightGray' ? theme['dark'] : theme['accent']
				const tagsTitle = this.color == 'white' || this.color == 'lightGray' ? theme['accent'] : "#ffffff"
				const tags = this.color == 'white' || this.color == 'lightGray' ? theme['tags'] : "#ffffff"

				return {
					'--bg-color': theme['base'],
					'--dark': theme['dark'],
					'--icon-color':theme['accent'],
					'--section-title-color':sectionTitle,
					'--text-color':theme['text'],
					'--tags-bg-color': tagsBg,
					'--tags-title-color': tagsTitle,
					'--tags-color':tags,
				}
			}
		}
	}
</script>

<style scoped>
	.content{
		@apply px-0; 
		@apply sm:px-s6;
	}

	.box{
		background-color:var(--bg-color);
	}


	.notch-left{
		clip-path: polygon(0 32px, 32px 0, 100% 0, 100% 100%, 0 100%, 0 32px);
	}
	.notch-right{
		clip-path: polygon(0 0, calc(100% - 32px) 0, 100% 32px, 100% 100%, 0 100%, 0 0);
	}

</style>
