import { createApp } from 'vue';
import PortalVue from 'portal-vue';
import VBodyScrollLock from 'v-body-scroll-lock';

import '@src/css/main.pcss';
// import './components.js';

/* Import components */
const eagerComponents = import.meta.glob(
  ['./components/**/*.vue', '!./components/**/*.lazy.vue'],
  { eager: true }
);
const lazyComponents = import.meta.glob('./components/**/*.lazy.vue');

const app = createApp({
  delimiters: ['${', '}'],
});

//register eager components
Object.entries(eagerComponents).forEach(([path, definition]) => {
  const componentName = path.split('/').pop().replace('.vue', '');
  app.component(componentName, definition.default);
});

//register lazy components
Object.entries(lazyComponents).forEach(([path, module]) => {
  const componentName = path.split('/').pop().replace('.lazy.vue', '');
  const componentDefinition = defineAsyncComponent(module);
  app.component(componentName, componentDefinition);
});

app.use(PortalVue);
app.use(VBodyScrollLock);
app.mount('#app');

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR');
  });
}
