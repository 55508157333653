<script>
	export default {
		props: {
			href: {
				type: String,
				default: null
			},
			target: {
				type: String,
				default: null
			},
			dark: {
				type: Boolean,
				default: false
			},
			as: String,
			icon: String,
		},
		computed: {
			htmlElement() {
				// const dynamic = this.href ? 'a' : 'button'
				return this.as
			}
		},
		methods: {
			customClick() {
				if (this.$listeners.click) {
					this.$emit('click');
				}
			}
		}
	};
</script>


<template>
	<component
		:is="htmlElement"
		:href="href"
		:target="target"
		@click="customClick"
		class="flex items-center w-full border-t-2 link-item py-s8 mt-0 text-left hover:border-red-500 transition-all"
		:class="dark == true ? 'border-gray-800 hover:border-red-500 text-white faux-p-xl' : 'border-gray-100 text-gray-900 faux-p-lg'"
	>
		<icon v-if="icon" :name="icon" size="sm" class="icon mr-s7 text-gray-500 transition-all"></icon>

		<div class="flex-grow">
			<slot name="text" />
		</div>

		<icon v-if="as !== 'div'" :name="this.href ? 'arrowhead-right' : 'plus'" size="xs" class="icon ml-s7 flex-shrink-0 text-gray-500 transition-all"></icon>
	</component>
</template>

<style scoped>
	#app .link-item:hover .icon{
		@apply text-red-500;
	}
</style>