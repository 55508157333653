<template>
	<box :color="color" :notch="notch" :type="type">
		<div class="h-[240px] relative bg-gray-50" v-if="$slots.image">
			<slot name="image"></slot>
		</div>
		<div class="container w-full px-s8 py-[56px]">
			<div class="content relative">
				<icon v-if = 'icon' :name="icon.name" :size="icon.size" class="icon mb-s8"></icon>
				<slot name="content"></slot>
				<span v-if="number" class="absolute top-0 right-s6 number w-min pt-[8px] ml-s5 before:absolute before:w-full before:h-[2px] before:bg-white before:top-[-2px] before:left-0 before:mt-0  before:opacity-20">{{number}}</span>
			</div>
		</div>
		<slot name="tags"></slot>
	</box>
</template>

<script>
	export default {
		props: {
			type: {
				type: String,
				default: 'fancy-box'
			},
			color: null,
			notch: null,
			number: {
				type : String,
				default : null
			},
			icon: Object,
		},

	}
</script>

<style scoped>
	.icon{
		color: var(--icon-color);
	}
	.content{

			/* @container (width < 400px){
				padding-left:24px;
				padding-right:24px;
			} */
	}
	.number{
		@apply font-display;
		@apply text-14;
		@apply leading-150;
		@apply text-white;
		@apply opacity-60;
		letter-spacing: .15em;
	}
</style>
