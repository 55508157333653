<template>
	<box :color="color" notch="none" type="quote">
		<slot>
			<img class="absolute top-[-70px] left-[36px] mix-blend-multiply z-10" src="@assets/test-images/quote mark.svg" />
			<div class="w-full px-s8 py-s12 mt-s12 mb-s10 notch-right">
				<div class="px-s2 sm:px-s9">
					<div v-html="formattedQuote"></div>
					<div class="flex mt-s10 items-center">
						<img v-if="image" :src="image" :alt="imageTitle" class="rounded-full w-[60px] h-[60px] mr-s5" />
						<icon v-else name="arrowhead-heavy-right" size="tiny" class="self-start mr-s6 opacity-40 mt-[-3px]" :class="color == 'lightGray' ? 'text-gray-500' : 'byline'"></icon>
						<div>
							<h6 :class="color == 'lightGray' ? '' : 'byline'">{{ name }}</h6>
							<p v-if="titleCompany" class="faux-p-sm mt-s4" :class="color == 'lightGray' ? 'text-gray-500' : 'byline'">{{ titleCompany }}</p>
						</div>
					</div>
				</div>
			</div>
		</slot>
	</box>
</template>

<script>
	export default {
		props: {
			color: String,
			quote: String,
			name: String,
			titleCompany: String,
			image: String,
			imageTitle: String
		},
		computed: {
			formattedQuote() {
				return this.quote.replace('<p>', '<p class="faux-p-lg quote font-bold">')
			}
		}
	}
</script>

<style>

	.quote {
		color: var(--text-color) !important;
	}

	#app *.byline{
		color: var(--dark);
	}

	.notch-right{
		clip-path: polygon(0 0, calc(100% - 32px) 0, 100% 32px, 100% 100%, 0 100%, 0 0);
		background-color: var(--bg-color);
	}

	.box {
		background-color:transparent;
	}
</style>
