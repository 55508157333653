<script>

	export default {
		name: "Modal",
		props : {
			image:String,
			imageTitle:String,
			size: {
				validator: function (value) {
					return ['large', 'default'].includes(value);
				},
				default: 'default'
			}
		},
		data() {
			return {
				show: false
			}
		},
		methods: {
			handleEscape(e) {
				if (e.keyCode == 27) {
					this.closeModal();
				}
			},
			closeModal() {
				this.show = false;
				this.$emit('modal-closed')
			},
			openModal() {
				this.show = true;
				console.log(this.show)
				this.$emit('modal-open')
			}
		},
		created() {
			document.addEventListener("keydown", this.handleEscape);
		},
		beforeDestroy() {
			document.removeEventListener("keydown", this.handleEscape);
		},
	};
</script>


<template>
	<portal to="modal">
		<fade>
		<div
			class="fixed inset-0 w-screen h-screen overflow-y-scroll z-50 modal-wrapper"
			v-bsl:reserve-scroll-bar-gap="true"
			v-if="show"
		>
			<div
				class="w-full min-h-full p-40 m-auto flex justify-center items-center"
				@click="closeModal()"
			>

				<div class="w-full mx-auto bg-white relative" :class="size == 'large' ? 'modal-container-lg' : 'modal-container'" @click.stop >

					<corner-btn iconType="close" color = 'red' @click.native.stop="closeModal()" class="absolute right-0 top-0" style="transform:scaleY(-1)"></corner-btn>

					<div class="modal__header bg-gray-50 text-32 text-gray-900 font-medium font-display pt-[50px] px-[60px]" :class="image ? 'pb-[80px]' : 'pb-[55px]'"v-if="!!$slots.header">
						<slot name="header"/>
					</div>
					<div class="modal__info py-[60px] px-[60px]" v-if="!!$slots.body">
						<img v-if="image" :src="image" :alt="imageTitle" class="w-full h-[188px] mt-[-84px] mb-[52px] object-cover" style="clip-path: polygon(0 0, calc(100% - 32px) 0, 100% 32px, 100% 100%, 0 100%, 0 0);" />

						<slot name="body"/>
					</div>

					<slot />

				</div>

			</div>
		</div>
		</fade>
	</portal>
</template>

<style scoped>
	.modal-wrapper{
		background: rgba(33, 33, 34, 0.9);
	}
	.modal-container{
		max-width: 620px;
	}
	.modal-container-lg{
		max-width:960px;
	}
</style>
