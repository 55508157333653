<template>
  <svg :class="classes">
    <use :xlink:href="path"></use>
  </svg>
</template>

<script>
import { icons } from '../../../config/build/carbon.mjs'

function sizeExists(size) {
  return Object.keys(icons).includes(size)
}

export default {
  props: {
    name: String,
    size: {
      tiny: String,
      validator: sizeExists
    },
  },
  computed: {
    _size() {
      return icons[this.size]
    },
    path() {
      const { abbr, folder } = this._size
      const path = `/assets/icons/${folder}/${abbr}-${this.name}.svg#icon-definition`
			const url = encodeURI(path)
			return url
    },
		classes() {
			return [`w-icon-${this.size}`, `h-icon-${this.size}`]
		}
  }
}
</script>
