<template>
  <a
    :href="url"
    ref="resourceBox"
    class="
      block
      bg-section-theme
      button-parent
      resource-box
      w-full
      relative
      pt-[67%]
      overflow-hidden
      cursor-pointer
      transition-all
    "
    :class="{ 'resource-box--image': image }"
    :style="imageCSS"
    @click="
      modal && Object.keys(modal).length
        ? $refs.resourceBoxModal.openModal()
        : ''
    "
  >
    <div class="bg-hover-layer w-full absolute inset-0 transition-all">
      <div
        class="absolute flex flex-col left-s9 right-s10 top-s9 bottom-s8 pr-s6"
        :class="number ? 'justify-between' : 'justify-end'"
      >
        <span
          v-if="number"
          class="
            ui
            w-min
            relative
            text-white
            before:absolute
            before:w-full
            before:h-[2px]
            before:bg-white
            before:top-[-9px]
            before:left-0
            before:mt-0
            before:opacity-25
          "
          >{{ number }}</span
        >
        <div>
          <p class="text-white" :class="tags ? 'mb-s2' : ''" v-html="title"></p>
          <span
            v-if="label"
            class="inline-block ui-xs text-red-500 mr-[11px] mt-s4"
            v-html="label"
          ></span>
          <template v-if="tags">
            <span
              v-for="tag in tags"
              :key="tag"
              class="
                ui-xs
                mt-0
                tag
                relative
                inline-block
                whitespace-nowrap
                mr-[11px]
              "
            >
              {{ tag }}
            </span>
          </template>
        </div>
      </div>
      <corner-btn
        v-if="button"
        :color="button.color"
        :iconType="button.type"
        class="absolute bottom-[-1px] right-[-1px]"
      ></corner-btn>

      <modal
        v-if="modal"
        ref="resourceBoxModal"
        :image="modal.image"
        :imageTitle="modal.imageTitle"
        :size="modal.size"
      >
        <template v-slot:header>
          <h2 class="faux-h3" v-if="modal.heading">{{ modal.heading }}</h2>
          <p v-if="modal.subheading">{{ modal.subheading }}</p>
        </template>

        <template v-slot:body>
          <div v-html="modal.text"></div>
        </template>
      </modal>
    </div>
  </a>
</template>

<script>
export default {
  name: "resource-box",
  props: {
    color: null,
    columns: null,
    title: String,
    number: null,
    label: null,
    tags: Array,
    button: Object,
    image: String,
    url: String,
    modal: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    imageCSS() {
      return {
        "--bg-image-path": `url(${this.image})`,
      };
    },
  },
};
</script>

<style lang="postcss" scoped>
.resource-box--image {
  background: linear-gradient(
      360deg,
      rgba(33, 33, 34, 0.95) 16.25%,
      rgba(33, 33, 34, 0.0545) 58.78%,
      rgba(33, 33, 34, 0) 68.1%
    ),
    var(--bg-image-path);
  background-position: center top;
  background-size: cover;
}

.bg-hover-layer:hover {
  background-color: transparent;
}

.bg-hover-layer:hover {
  background-color: rgba(33, 33, 34, 0.6);
}

.tag {
  color: white;
  @apply opacity-60;
}

.tag::after {
  content: "";
  background-color: white;
  height: 2px;
  bottom: -8px;

  @apply absolute;
  @apply w-full;
  @apply left-0;
  @apply mt-0;
  @apply opacity-20;
}
</style>
