<script>
	export default {
		name: "Consent",
		props : {
			message: {
				type: String,
				default: 'We use cookies to improve your experience on our website.'
			},
			buttontext: {
				type: String,
				default: 'Got it!'
			},
			expireDays: {
				type: Number,
				default: 60
			}
		},
		data() {
			return {
				show: false,
				cookieName: 'cookieConsent'
			}
		},
		computed: {
			cookie() {

				let currentDate = new Date().getTime();
				let storedDate = parseInt(this.getCookie());

				// - current date is greater than our stored date: this is now expired, delete cookie (local storage)
				if (currentDate > storedDate) {
					this.removeCookie();
				}

				return !this.getCookie();
			}
		},
		beforeMount() {
			this.show = this.cookie;
		},
		methods: {
			dismiss() {
				this.show = false;
				this.setCookie();
			},
			getCookie() {
				return localStorage.getItem(this.cookieName);
			},
			setCookie() {
				let storedDate = new Date();
				
				//set date + [x] days (default is 60 days)
				storedDate = storedDate.setDate(storedDate.getDate() + this.expireDays); //return value - milliseconds

				//store the date
				localStorage.setItem(this.cookieName, `${storedDate}`);
			},
			removeCookie() {
				localStorage.removeItem(this.cookieName);
			}
		}
	};
</script>


<template>
	<transition name="fade">
		<div class="consent fixed bottom-0 md:bottom-[30px] left-0 md:left-[30px] w-full bg-gray-50 p-s7" role="dialog"
		v-if="show"
		>
			<div class="consent-message" v-html="message"></div>

			<slot name="button">
				<btn class="consent-btn mt-s7" @click="dismiss" color="red" size="small">
					{{ buttontext }}
				</btn>
			</slot>

		</div>
	</transition>
</template>


<style scoped>
	.consent{
		z-index: 99;
		box-shadow: 0 0 8px 0 rgba(0,0,0, .25);
	}
	@media (min-width: 768px){
		.consent{ max-width: 400px; }
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s;
	}
	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}
</style>