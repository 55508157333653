<template>
    <a :href="href" :target="target" @click="handleClick" class="button-parent feature-box w-full relative pt-[67%] overflow-hidden cursor-pointer transition-all " :class="color == 'image' ? 'image-bg' : color == 'logo' ? 'logo-bg': 'color-bg'" :style="{...colorCSS, ...imageCSS}">
        <div class="bg-hover-layer w-full absolute inset-0 transition-all">
            <div class="absolute flex flex-col justify-end left-s10 right-s12 top-s9 bottom-s10 pr-s6">
                <div>
                    <h5 v-if="color != 'logo'" class="transition-all relative z-10">{{ title }}</h5>
                </div>
            </div>
            <icon v-if="icon && !['image','logo'].includes(color)" class = 'icon absolute right-s9 top-s9' :size="icon.size" :name="icon.name"></icon>
            <corner-btn v-if="button" :color="button.color" :iconType="button.type" class="absolute bottom-[-1px] right-[-1px]" :class="['image','logo'].includes(color) ? 'custom-transition' : ''"></corner-btn>
        </div>        
    </a>



</template>
<script>

	import { colors } from '../../../config/build/carbon.mjs';

	const boxColors = ['gray', 'red', 'green', 'teal']

	const coloredFBColorSchemes = ['red', 'green', 'teal'].reduce((acc, color) => ({
		[color]:{
			base: colors[color][500],
			dark: colors[color][800],
			accent: colors[color][600],
			text: "#ffffff"
		},
		...acc
	}), {})

    const featureBoxColorSchemes = {
        ...coloredFBColorSchemes,

        gray:{
            base:colors['gray'][50],
            accent:colors['gray'][200],
            text:colors['gray'][900]
        }
    }


    export default {
        name:'feature-box',
        data: function () {
            return {
                loaded:false,
                eltHeight:null
            }
        },
        props: {
            href: {
				type: String,
				default: null
			},
			target: {
				type: String,
				default: null
			},
            color: null,
            title: String,
			image: String,
			number: Number,
			icon: Object,
            button: Object,
        },
        methods: {
            handleClick(event) {
				if (this.$attrs.click) {
					this.$emit('click');
				}
            }
        },
        computed: {
            colorCSS () {
                if(this.color != "image" && this.color != "logo"){
                    const theme = featureBoxColorSchemes[this.color];
                    const hoverBG = this.color == 'gray' ? colors['red'][500] : theme['accent']
                    const hoverIcon = this.color == 'gray' ? colors['red'][600] : theme['dark']
                    const hoverText = this.color == 'gray' ? "#ffffff" : theme['text']

                    return {
                        '--bg-color':theme['base'],
                        '--icon-color':theme['accent'],
                        '--hover-bg-color':hoverBG,
                        '--hover-icon-color':hoverIcon,
                        '--text-color': theme['text'],
                        '--hover-text-color': hoverText,
                    }
                }
                else{
                    return {
                        '--text-color': "#ffffff",
                        '--hover-text-color':"#ffffff",
                        '--hover-button-color': colors['red'][500],
                        '--hover-button-icon-color': "#ffffff"
                    }
                }

            },
            imageCSS () {
                return {
                    '--bg-image-path': `url(${this.image})`
                }
            }
        }
	}
</script>

<style scoped>
    .feature-box.color-bg{
		background-color:var(--bg-color);

    }
    .feature-box.color-bg:hover{
		background-color:var(--hover-bg-color);
    }

    .feature-box.image-bg {
        background: linear-gradient(360deg, rgba(33, 33, 34, 0.95) 16.25%, rgba(33, 33, 34, 0.0545) 58.78%, rgba(33, 33, 34, 0) 68.1%), var(--bg-image-path);
        background-position: center top;
        background-size: auto 100%;
    }

    .feature-box.logo-bg {
        background: var(--bg-image-path);
        background-color: #F2F2F2;
        background-position: center;
        background-repeat: no-repeat;
    }

    .feature-box.image-bg:hover .bg-hover-layer, .feature-box.logo-bg:hover .bg-hover-layer{
        background-color: rgba(33,33,34, .6)
    }

    .feature-box .faux-h3{
        color:var(--text-color)
    }

    .feature-box:hover .faux-h3{
        color:var(--hover-text-color)
    }

    .button-parent:hover .corner-button.custom-transition{
		background-color: var(--hover-button-color);
        color: var(--hover-button-icon-color)
	}

    .feature-box .icon{
		color:var(--icon-color);
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 450ms;
    }
    .feature-box:hover .icon{
		color:var(--hover-icon-color);
    }
</style>
