export const colors = {
  gray: {
    50: '#f2f2f2',
    100: '#e4e4e4',
    200: '#cacaca',
    300: '#afafaf',
    400: '#959595',
    500: '#7a7a7a',
    600: '#646464',
    700: '#4e4e4e',
    800: '#373738',
    900: '#212122',
    '900-transparent': 'rgba(33,33,34,0)',
  },
  red: {
    50: '#fceced',
    100: '#f8d9da',
    200: '#f2b3b6',
    300: '#eb8d91',
    400: '#e5676d',
    500: '#de4148',
    600: '#b9343a',
    700: '#93282d',
    800: '#6e1b1f',
    900: '#490e11',
  },
  teal: {
    50: '#eaf6f8',
    100: '#d4edf0',
    200: '#a9dce1',
    300: '#7fcad1',
    400: '#54b9c2',
    500: '#29a7b3',
    600: '#218790',
    700: '#19676e',
    800: '#11474b',
    900: '#092728',
  },
  green: {
    50: '#edf7e9',
    100: '#daeed2',
    200: '#b5dda4',
    300: '#a1cd8a',
    400: '#8dbd71',
    500: '#79ad57',
    600: '#66904c',
    700: '#537240',
    800: '#405535',
    900: '#2d3729',
  },
};

export const typography = {
  fonts: {
    display: {
      family: ['TTLakes', 'sans-serif'],
      offset: { cap: 0.13, midCap: 0.22, baseline: 0.18 },
    },
    sans: {
      family: ['Inter', 'Arial', 'sans-serif'],
      offset: { cap: 0.14, midCap: 0.22, baseline: 0.14 },
    },
  },

  styles: {
    sh: {
      fontFamily: 'display',
      fontWeight: 'semibold',
      fontSize: { base: '20', md: '22' },
      lineHeight: { base: '150' },
      letterSpacing: '15',
      uppercase: true,
      textColor: 'red-500',
    },
    h1: {
      fontFamily: 'display',
      fontWeight: 'normal',
      fontSize: { base: '48', md: '72' },
      lineHeight: { base: '120' },
      textColor: 'gray-900',
      spacing: {
        sh: { base: 's9', md: 's10' },
      },
    },
    h2: {
      fontFamily: 'display',
      fontWeight: 'normal',
      fontSize: { base: '36', md: '48' },
      lineHeight: { base: '130' },
      textColor: 'gray-900',
      spacing: {
        sh: { base: 's8', md: 's9' },
        h1: { base: 's11', md: 's12' },
        h2: { base: 's10', md: 's11' },
        h3: { base: 's9', md: 's10' },
        h4: { base: 's9', md: 's10' },
        h5: { base: 's9', md: 's10' },
        h6: { base: 's9', md: 's10' },
        'p-xl': { base: 's12', md: 's13' },
        'p-lg': { base: 's12', md: 's13' },
        p: { base: 's12', md: 's13' },
        'p-sm': { base: 's12', md: 's13' },
        ul: { base: 's12', md: 's13' },
        ol: { base: 's12', md: 's13' },
        blockquote: { base: 's12', md: 's13' },
      },
    },
    h3: {
      fontFamily: 'display',
      fontWeight: 'normal',
      fontSize: { base: '28', md: '34' },
      lineHeight: { base: '130', md: '120' },
      textColor: 'gray-900',
      spacing: {
        sh: { base: 's8', md: 's9' },
        h1: { base: 's10', md: 's11' },
        h2: { base: 's9', md: 's10' },
        h3: { base: 's9', md: 's10' },
        h4: { base: 's9', md: 's10' },
        h5: { base: 's9', md: 's10' },
        h6: { base: 's9', md: 's10' },
        'p-xl': { base: 's11', md: 's12' },
        'p-lg': { base: 's11', md: 's12' },
        p: { base: 's11', md: 's12' },
        'p-sm': { base: 's11', md: 's12' },
        ul: { base: 's11', md: 's12' },
        ol: { base: 's11', md: 's12' },
        blockquote: { base: 's11', md: 's12' },
      },
    },
    h4: {
      fontFamily: 'display',
      fontWeight: 'semibold',
      fontSize: { base: '17', md: '20' },
      lineHeight: { base: '150' },
      letterSpacing: '15',
      uppercase: true,
      textColor: 'red-500',
      spacing: {
        h1: { base: 's10', md: 's11' },
        h2: { base: 's9', md: 's10' },
        h3: { base: 's9', md: 's10' },
        h4: { base: 's8', md: 's9' },
        h5: { base: 's8', md: 's9' },
        h6: { base: 's8', md: 's9' },
        'p-xl': { base: 's11', md: 's12' },
        'p-lg': { base: 's11', md: 's12' },
        p: { base: 's11', md: 's12' },
        'p-sm': { base: 's11', md: 's12' },
        ul: { base: 's11', md: 's12' },
        ol: { base: 's11', md: 's12' },
        blockquote: { base: 's11', md: 's12' },
      },
    },
    h5: {
      fontFamily: 'display',
      fontWeight: 'normal',
      fontSize: { base: '24', md: '26' },
      lineHeight: { base: '130' },
      textColor: 'gray-900',
      spacing: {
        h1: { base: 's10', md: 's11' },
        h2: { base: 's9', md: 's10' },
        h3: { base: 's9', md: 's10' },
        h4: { base: 's8', md: 's9' },
        h5: { base: 's8', md: 's9' },
        h6: { base: 's8', md: 's9' },
        'p-xl': { base: 's11', md: 's12' },
        'p-lg': { base: 's11', md: 's12' },
        p: { base: 's11', md: 's12' },
        'p-sm': { base: 's11', md: 's12' },
        ul: { base: 's11', md: 's12' },
        ol: { base: 's11', md: 's12' },
        blockquote: { base: 's11', md: 's12' },
      },
    },
    h6: {
      fontFamily: 'display',
      fontWeight: 'bold',
      fontSize: { base: '15', md: '18' },
      lineHeight: { base: '150' },
      letterSpacing: '15',
      uppercase: true,
      textColor: 'red-500',
      spacing: {
        h1: { base: 's10', md: 's11' },
        h2: { base: 's9', md: 's10' },
        h3: { base: 's9', md: 's10' },
        h4: { base: 's8', md: 's9' },
        h5: { base: 's8', md: 's9' },
        h6: { base: 's8', md: 's9' },
        'p-xl': { base: 's11', md: 's12' },
        'p-lg': { base: 's11', md: 's12' },
        p: { base: 's11', md: 's12' },
        'p-sm': { base: 's11', md: 's12' },
        ul: { base: 's11', md: 's12' },
        ol: { base: 's11', md: 's12' },
        blockquote: { base: 's11', md: 's12' },
      },
    },
    'p-xl': {
      fontFamily: 'sans',
      fontWeight: 'normal',
      fontSize: { base: '22', md: '24' },
      lineHeight: { base: '170' },
      spacing: {
        h1: { base: 's11', md: 's12' },
        h2: { base: 's10', md: 's11' },
        h3: { base: 's10', md: 's10' },
        h4: { base: 's9', md: 's10' },
        h5: { base: 's9', md: 's10' },
        h6: { base: 's9', md: 's10' },
        'p-xl': { base: 's10', md: 's11' },
        'p-lg': { base: 's10', md: 's11' },
        p: { base: 's10', md: 's11' },
        'p-sm': { base: 's10', md: 's11' },
        ul: { base: 's10', md: 's11' },
        ol: { base: 's10', md: 's11' },
        blockquote: { base: 's10', md: 's11' },
      },
    },
    'p-lg': {
      fontFamily: 'sans',
      fontWeight: 'normal',
      fontSize: { base: '20', md: '22' },
      lineHeight: { base: '170' },
      spacing: {
        h1: { base: 's11', md: 's12' },
        h2: { base: 's10', md: 's11' },
        h3: { base: 's10', md: 's10' },
        h4: { base: 's9', md: 's10' },
        h5: { base: 's9', md: 's10' },
        h6: { base: 's9', md: 's10' },
        'p-xl': { base: 's10', md: 's11' },
        'p-lg': { base: 's10', md: 's11' },
        p: { base: 's10', md: 's11' },
        'p-sm': { base: 's10', md: 's11' },
        ul: { base: 's10', md: 's11' },
        ol: { base: 's10', md: 's11' },
        blockquote: { base: 's10', md: 's11' },
      },
    },
    p: {
      fontFamily: 'sans',
      fontWeight: 'normal',
      fontSize: { base: '16', md: '18' },
      lineHeight: { base: '170' },
      spacing: {
        sh: { base: 's8', md: 's9' },
        h1: { base: 's11', md: 's12' },
        h2: { base: 's10', md: 's11' },
        h3: { base: 's10', md: 's10' },
        h4: { base: 's9', md: 's10' },
        h5: { base: 's9', md: 's10' },
        h6: { base: 's9', md: 's10' },
        'p-xl': { base: 's10', md: 's11' },
        'p-lg': { base: 's10', md: 's11' },
        p: { base: 's10', md: 's10' },
        'p-sm': { base: 's10', md: 's10' },
        ul: { base: 's10', md: 's10' },
        ol: { base: 's10', md: 's10' },
        blockquote: { base: 's10', md: 's10' },
      },
    },
    'p-sm': {
      fontFamily: 'sans',
      fontWeight: 'normal',
      fontSize: { base: '14', md: '16' },
      lineHeight: { base: '170' },
      spacing: {
        h1: { base: 's11', md: 's12' },
        h2: { base: 's10', md: 's11' },
        h3: { base: 's10', md: 's10' },
        h4: { base: 's9', md: 's10' },
        h5: { base: 's9', md: 's10' },
        h6: { base: 's9', md: 's10' },
        'p-xl': { base: 's10', md: 's11' },
        'p-lg': { base: 's10', md: 's11' },
        p: { base: 's10', md: 's10' },
        'p-sm': { base: 's10', md: 's9' },
        ul: { base: 's10', md: 's10' },
        ol: { base: 's10', md: 's10' },
        blockquote: { base: 's10', md: 's10' },
      },
    },
    ul: {
      fontFamily: 'sans',
      fontWeight: 'normal',
      fontSize: { base: '18', md: '18' },
      lineHeight: { base: '170' },
      spacing: {
        h1: { base: 's11', md: 's12' },
        h2: { base: 's10', md: 's11' },
        h3: { base: 's10', md: 's11' },
        h4: { base: 's9', md: 's10' },
        h5: { base: 's9', md: 's10' },
        h6: { base: 's9', md: 's10' },
        'p-xl': { base: 's10', md: 's11' },
        'p-lg': { base: 's10', md: 's11' },
        p: { base: 's9', md: 's10' },
        'p-sm': { base: 's9', md: 's10' },
        ul: { base: 's8', md: 's9' },
        ol: { base: 's8', md: 's9' },
        blockquote: { base: 's9', md: 's10' },
      },
    },
    ol: {
      fontFamily: 'sans',
      fontWeight: 'normal',
      fontSize: { base: '18', md: '18' },
      lineHeight: { base: '170' },
      spacing: {
        h1: { base: 's11', md: 's12' },
        h2: { base: 's10', md: 's11' },
        h3: { base: 's10', md: 's11' },
        h4: { base: 's9', md: 's10' },
        h5: { base: 's9', md: 's10' },
        h6: { base: 's9', md: 's10' },
        'p-xl': { base: 's10', md: 's11' },
        'p-lg': { base: 's10', md: 's11' },
        p: { base: 's9', md: 's10' },
        'p-sm': { base: 's9', md: 's10' },
        ul: { base: 's8', md: 's9' },
        ol: { base: 's8', md: 's9' },
        blockquote: { base: 's9', md: 's10' },
      },
    },
    blockquote: {
      fontFamily: 'sans',
      fontWeight: 'normal',
      fontSize: { base: '18', md: '18' },
      lineHeight: { base: '170' },
      spacing: {
        h1: { base: 's11', md: 's12' },
        h2: { base: 's10', md: 's11' },
        h3: { base: 's10', md: 's11' },
        h4: { base: 's9', md: 's10' },
        h5: { base: 's9', md: 's10' },
        h6: { base: 's9', md: 's10' },
        'p-xl': { base: 's10', md: 's11' },
        'p-lg': { base: 's10', md: 's11' },
        p: { base: 's9', md: 's10' },
        'p-sm': { base: 's9', md: 's10' },
        ul: { base: 's9', md: 's10' },
        ol: { base: 's9', md: 's10' },
        blockquote: { base: 's9', md: 's10' },
      },
    },
  },
};

export const icons = {
  tiny: { abbr: 't', size: '16px', folder: 'tiny' },
  xs: { abbr: 'xs', size: '24px', folder: 'extra-small' },
  sm: { abbr: 's', size: '32px', folder: 'small' },
  md: { abbr: 'm', size: '48px', folder: 'medium' },
  lg: { abbr: 'l', size: '72px', folder: 'large' },
  xl: { abbr: 'xl', size: '96px', folder: 'extra-large' },
  giant: { abbr: 'g', size: '144px', folder: 'giant' },
};
