<script>
import { colors } from '../../../config/build/carbon.mjs';

const buttonColors = ['red', 'teal', 'green']
const buttonSizes = {
	base: {},
	small: {},
}

const buttonThemes = buttonColors.reduce((acc, color) => ({
	[color]: {
		base: {
			base: {
				enabled: {
					base: {
						text: colors[color]['500'],
						underline: colors[color]['100']
					},
					hover: {
						text: colors[color]['600'],
						underline: colors[color]['600'],
					},
				},
				disabled: {
					base: {
						text: colors[color]['100'],
						underline: colors[color]['100'],
					},
					hover: {
						text: null,
						underline: null
					}
				}
			},
			dark: {
				enabled: {
					base: {
						text: colors[color]['800'],
						underline: colors[color]['600']
					},
					hover: {
						text: colors[color]['500'],
						underline: colors[color]['600'],
					},
				},
				disabled: {
					base: {
						text: colors[color]['100'],
						underline: colors[color]['100'],
					},
					hover: {
						text: null,
						underline: null
					}
				}
			}
		},
		inverted: {
			base: {
				enabled: {
					base: {
						text: colors[color]['500'],
						underline: colors[color]['700']
					},
					hover: {
						text: 'white',
						underline: colors[color]['500'],
					},
				},
				disabled: {
					base: {
						text: 'rgba(255, 255, 255, 0.3)', // white @ 30% transparency
						underline: 'rgba(255, 255, 255, 0.3)', // white @ 20% transparency
					},
					hover: {
						text: null,
						underline: null
					}
				}
			},
			dark: {
				enabled: {
					base: {
						text: colors[color]['800'],
						underline: colors[color]['600']
					},
					hover: {
						text: colors['gray']['50'],
						underline: colors[color]['600'],
					},
				},
				disabled: {
					base: {
						text: 'rgba(255, 255, 255, 0.3)', // white @ 30% transparency
						underline: 'rgba(255, 255, 255, 0.3)', // white @ 20% transparency
					},
					hover: {
						text: null,
						underline: null
					}
				}
			}
		},
	},
	...acc
}), {})

export default {
	props: {
		size: {
			type: String,
			default: 'base',
			validator: (value) => Object.keys(buttonSizes).includes(value)
		},
		color: {
			type: String,
			default: 'red',
			validator: (value) => buttonColors.includes(value)
		},
		dark: {
			type: Boolean,
			default: false
		},
		inverted: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},

		as: {
			type: String,
			validator: (value) => ['a', 'button'].includes(value)
		},

		iconLeft: String,
		iconRight: String,
	},
	computed: {
		colorCSS() {
			const { color, inverted, dark, disabled } = this

			const theme = buttonThemes[color][inverted ? 'inverted' : 'base'][dark ? 'dark' : 'base'][disabled ? 'disabled' : 'enabled']

			return {
				'--text-color': theme.base.text,
				'--text-color-hover': theme.hover.text,
				'--underline-color': theme.base.underline,
				'--underline-color-hover': theme.hover.underline
			}
		},
		htmlElement() {
			const defaultElement = 'a'
			return this.as ? this.as : defaultElement
		}
	},
	methods: {
		handleClick(event) {
			if(this.htmlElement === 'button') {
				this.$emit('click', event)
			}
		}
	}
}
</script>

<template>
	<component
		:is="htmlElement"
		@click="handleClick"

		class="styled-link"
		:class="{ 'py-[4px]' : (size === 'base') }"
		:style="{ ...colorCSS }"

		:disabled="disabled ? disabled : null"
	>
		<UiTextWithIcons
			:size="size"
			:iconLeft="iconLeft"
			:iconRight="iconRight"
		>
			<slot />
		</UiTextWithIcons>
	</component>
</template>

<style scoped>
	.styled-link {
		color: var(--text-color);
		border-bottom: 2px solid var(--underline-color);

		transition: 400ms color, 400ms border-color;
	}

	.styled-link:not([disabled]):hover,
	.styled-link:not([disabled]):focus,
	.styled-link.hover {
		color: var(--text-color-hover);
		border-color: var(--underline-color-hover);
	}

	.styled-link:focus {
		outline: none;
	}
</style>
