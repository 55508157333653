<template>
	<div class="header-rotator max-w-[750px] py-s12 h-full mx-s8 lg:ml-[var(--offset)] lg:mr-s13 flex flex-col justify-center">
		<h2 class="faux-h6 md:faux-sh text-gray-200 to-lg:pl-s11 to-lg:h-[66px] to-lg:flex to-lg:items-center">{{ subheading }}</h2>
		<div class="relative" :style="activeSlide.colorCSS">
			<div class="icon-container h-[66px] lg:h-[150px] flex absolute top-[-66px] lg:top-[-2px] to-lg:-left-s8">
				<div class="w-[68px] lg:w-[140px] h-full slide-icon-bg flex flex-col justify-center">
					<transition name="fade" mode="out-in">
						<div :key="activeSlide.icon">
							<icon :name="activeSlide.icon" size="md" class="ml-[17px] slide-icon lg:hidden"></icon>
							<icon :name="activeSlide.icon" size="xl" class="ml-[43px] slide-icon to-lg:hidden"></icon>
						</div>
					</transition>
				</div>
				<div class="w-[24px] lg:w-[64px] relative left-[-1px] h-full hexagon-side slide-icon-bg"></div>
			</div>
			<transition name="fade" mode="out-in">
				<h1 class="text-white mt-s10 md:mt-s9" :key="activeSlide.icon"><span class="slide-text">{{ activeSlide.heading[0] }}</span> {{ activeSlide.heading[1] }}</h1>
			</transition>
		</div>
		<div class="flex mt-s10 m-[-4px]">
			<div class="p-[4px] cursor-pointer" v-for="(slide, i) in slides" :key="i" @click="activeSlideIndex = i">
				<div class="w-[24px] h-[2px]" :class="activeSlideIndex == i ? 'bg-white' : 'bg-gray-700'"></div>
			</div>
		</div>
	</div>
</template>

<script>
import { colors } from '../../../config/build/carbon.mjs';

export default {
	props: ['subheading', 'content'],
	data() {
		return {
			activeSlideIndex: 0
		}
	},
	computed: {
		slides() {
			let parsedContent = JSON.parse(this.content);
			return Object.values(parsedContent).map(slide => ({
				...slide,
				heading: slide.heading.split(/ (.+)/),
				colorCSS: {
					'--text-color': colors[slide.color]['500'],
					'--icon-bg-color': colors[slide.color]['500'],
					'--icon-color': colors[slide.color]['600'],
				}
			}))
		},
		activeSlide() {
			return this.slides[this.activeSlideIndex];
		}
	},
	watch: {
		activeSlideIndex: {
			immediate: true,
			handler(val) {
				setTimeout(() => {
					if (this.slides.length == val + 1) {
						this.activeSlideIndex = 0;
					} else {
						this.activeSlideIndex++;
					}
				}, 5000)
			}
		}
	}
}
</script>

<style scoped lang="postcss">
	.header-rotator {
		--offset: 285px;

		@media (min-width: theme('maxWidth.content')) {
			--offset: calc(285px - ((100vw - theme('maxWidth.content')) / 2));
		}

		@media (min-width: calc(theme('maxWidth.content') + 570px)) {
			--offset: 0px;
		}
	}

	.icon-container {
		left: calc(var(--offset) * -1);

		@media (min-width: theme('maxWidth.content')) {
			left: calc(((100vw - theme('maxWidth.content')) / 2 * -1) - 1px - var(--offset));
		}
	}

	.fade-enter-from,
	.fade-leave-to {
		@apply opacity-0;
	}

	.fade-enter-to,
	.fade-leave-from {
		@apply opacity-100;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 1s;
	}

	.slide-text {
		transition: color 2s;
		color: var(--text-color);
	}

	.slide-icon-bg {
		transition: background-color 2s;
		background-color: var(--icon-bg-color);
	}

	.slide-icon {
		transition: color 2s;
		color: var(--icon-color);
	}
</style>