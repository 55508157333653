<script>
	import Modal from  './Modal.vue';

	export default {
		components: {
			Modal,
		},
		props: {
			type: String,
			day: String,
			month: String,
			year: String,
			image: String,
			imageTitle:String,
			icon:String,
			borderColor: String,
			iconColor: String,
		},
		data : function () {
			return {
				monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
			}
		},
		methods : {
			prependZero : function (number) {
				if(number.toString().length == 1){
					return '0' + number.toString();
				}
				else{
					return number.toString();
				}
			}
		},
		computed : {
			date : function () {
				if(![this.day, this.month, this.year].includes('')){
					console.log(this.day)
					return new Date(this.year, this.month, this.day);
				}
				else{
					return null;
				}
			}
		}
	};
</script>


<template >
    <button
         v-if="!!($slots.modal||$slots.modalheader||$slots.modalbody)"
        @click="$refs.listModal.openModal()"
        class=" flex items-center w-full border-t-2 link-item py-s8 mt-0 text-left border-red-600" :class="type == 'calendar' ? 'border-red-600 text-white faux-p' : type == 'dark' ? 'border-gray-800 hover:border-red-500 text-white faux-p-xl' : 'border-gray-100 hover:border-red-500 text-gray-900 faux-p-lg'"
    >

		<div v-if="type == 'calendar'" class="calendar w-s10 h-s10 flex-shrink-0 mr-s7">
			<div class="cal-top h-[25px] w-full bg-gray-50 flex justify-center items-center">
				<p class="faux- ui-sm text-gray-900">{{monthNames[date.getMonth()]}}</p>
			</div>
			<div class="cal-bot bg-white h-[39px] w-full flex justify-center items-center">
				<p class="relative faux-h3 font-medium text-red-500 bottom-[-2px]">{{date.getDate()}}</p>
			</div>
		</div>
		<img v-else-if="image" :src="image" :alt="imageTitle" class="w-s12 h-s12 mr-s7 object-cover"></img>
		<icon v-else-if="icon" :name="icon" size="sm" class="mr-s7" :class="type == 'calendar' ? 'text-red-200': 'text-gray-500'"></icon>


        <!-- button text -->
		<div class="flex-grow">
			<p v-if="type != 'calendar' && date && day" class="ui faux- text-red-500 mb-s2">{{`${prependZero(date.getMonth())}.${prependZero(date.getDate())}.${date.getFullYear().toString().substring(2,4)}`}}</p>
			<slot name="text" />
		</div>

		<icon name="plus" size="xs" class="ml-s7 flex-shrink-0" :class="type == 'calendar' ? 'text-red-200': 'text-gray-500'"></icon>

        <!-- PortalVue: removes this and places at bottom of page -->
		<modal ref="listModal">
			<template v-slot:header>
				<slot name="modalheader" />
			</template>

			<template v-slot:body>
				<slot name="modalbody" />
			</template>

			<slot name="modal" />
		</modal>
    </button>

	<a
		v-else
		class="flex items-center w-full border-t-2 py-s8 mt-0"  :class="type == 'calendar' ? 'border-red-600 text-white faux-p' : type == 'dark' ? 'border-gray-800 hover:border-red-500  text-white faux-p-xl' : 'border-gray-100 hover:border-red-500 text-gray-900 faux-p-lg'"
	>

		<div v-if="type == 'calendar'" class="calendar w-s10 h-s10 flex-shrink-0 mr-s7">
			<div class="cal-top h-[25px] w-full bg-gray-50 flex justify-center items-center">
				<p class="faux- ui-sm text-gray-900">{{monthNames[date.getMonth()]}}</p>
			</div>
			<div class="cal-bot bg-white h-[39px] w-full flex justify-center items-center">
				<p class="relative faux-h3 font-medium text-red-500 bottom-[-2px]">{{date.getDate()}}</p>
			</div>
		</div>
		<img v-else-if="image" :src="image" :alt="imageTitle" class="w-s12 h-s12 mr-s7 object-cover"></img>
		<icon v-else-if="icon" :name="icon" size="sm" class="mr-s7" :class="type == 'calendar' ? 'text-red-200': 'text-gray-500'"></icon>

		<!-- link text -->
		<div class="flex-grow">
			<p v-if="type != 'calendar' && date && day" class="ui faux- text-red-500 mb-s2">{{`${prependZero(date.getMonth())}.${prependZero(date.getDate())}.${date.getFullYear().toString().substring(2,4)}`}}</p>
			<slot name="text" />
		</div>

		<icon name="arrowhead-right" size="xs" class="ml-s7 flex-shrink-0" :class="type == 'calendar' ? 'text-red-200': 'text-gray-500'"></icon>
	</a>
</template>