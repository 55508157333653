<template>
	<button class="block text-left" @click="handleClick">
		<fancy-box class="info-fancy-box" :style="`background-image: url(${bg})`" :color="color" notch="right" type="info-box" :number="number">
			<template v-slot:content>
				<h3 class="text-white">{{ title }}</h3>
				<p class="text-white mt-s9">{{ content }}</p>
				<icon name="plus" size="sm" class="text-white mt-s10"></icon>
			</template>
		</fancy-box>
	</button>
</template>

<script>
	export default {
		props : {
			color:String,
			number: String,
			title: String,
			content: String,
			bg: {
				type: String,
				default: ''
			}
		},
		methods: {
			handleClick(event) {
				this.$emit('click');
			}
		}
	}
</script>

<style>
	.info-fancy-box {
		background-repeat: no-repeat;
		background-position: bottom right;
	}
</style>
