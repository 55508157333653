<script>
export default {
	props: {
		criteria: {
			type: Object
		},
		filters: {
			type: Object
		},
		limit: {
			type: Number,
			default: null
		}
	},
	data() {
		return {
			entries: [],
			filterValues: {},
			isMounted: false,
			pages: 1,
			pagination: null,
			showLoadMore: false
		}
	},
	methods: {
		updateWindowUrl() {
			const params = new URLSearchParams(this.filterValues);
			const url = window.location.pathname + '?' + params.toString()
			window.history.pushState('', '', url);
		},
		async makeRequest() {
			const allParams = {
				...this.criteria,
				...this.filterValues,
				limit: this.limit * this.pages,
			}
			const params = new URLSearchParams(allParams);
			const url = `/api/dynamic-entries.json?${params.toString()}`

			const request = await fetch(url)
			const response = await request.json()

			this.entries = response.data
			this.pagination = response.meta.pagination

			// debugger
			this.showLoadMore = parseInt(response.meta.pagination['total_pages']) > 1
		},
		loadMore() {
			this.pages++
			this.makeRequest()
		},
	},
	watch: {
		filterValues: {
			handler(filterValues) {
				// Reset pagination
				this.pages = 1

				// Update entries
				this.makeRequest()

				// If this is a user-generated change, update the URL
				if(this.isMounted) {
					this.updateWindowUrl()
				} else {
					this.isMounted = true
				}
			},
			deep: true
		}
	},
	created() {
		const params = new URLSearchParams(window.location.search)

		const filterValues = Object.entries(this.filters).reduce((acc, [filterName, filterConfig]) => {
			const urlValue = params.get(filterName)
			const value = urlValue !== null ? urlValue : filterConfig.default

			return { ...acc, [filterName]: value }
		}, {})

		this.filterValues = filterValues
	},
	render() {
		return this.$slots.default({
			entries: this.entries,
			filterValues: this.filterValues,
			loadMore: this.loadMore,
			showLoadMore: this.showLoadMore
		})
	}
}
</script>
