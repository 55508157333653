<template>
	<div :style="colorCSS">
		<div class="max-w-content mx-auto flex">
			<div class="w-s8 lg:w-s13 flex justify-center items-center flex-shrink-0">
				<button @click="pageDown" :disabled="currentPage === 0">
					<icon size="sm" name="arrowhead-left" class="arrow to-lg:hidden"></icon>
					<icon size="xs" name="arrowhead-left" class="arrow lg:hidden"></icon>
				</button>
			</div>
			<div class="flex-grow">
				<div v-if="type == 'feature'" class="grid grid-cols-3 gap-s9">
					<resource-box v-for="(elt, index) in currentElements" :data="elt" :key="index" v-bind="elt">
					</resource-box>
				</div>
				<div v-else-if="type == 'spotlight'">
					<spotlight v-for="(elt, index) in currentElements" :data="elt" :key="index" v-bind="elt">
					</spotlight>
				</div>
			</div>
			<div class="w-s8 lg:w-s13 flex justify-center items-center flex-shrink-0" >
				<button @click="pageUp" :disabled="elements.length < ((currentPage + 1) * perPage)">
					<icon size="sm" name="arrowhead-right" class="arrow to-lg:hidden"></icon>
					<icon size="xs" name="arrowhead-right" class="arrow lg:hidden"></icon>
				</button>
			</div>
		</div>
		<div class="h-s10 flex justify-center items-end space-x-s2">
			<div v-for="(elt, index) in new Array(totalPages)" :key="index" class="w-s6 h-[2px]" :class="index == currentPage ? 'curr-page' : 'not-curr-page'"></div>
		</div>
	</div>
</template>

<script>
	import { colors } from '../../../config/build/carbon.mjs';

	export default {
		props : {
			elements: Array,
			type: String
		},
		data : function() {
			return {
				currentPage: 0
			}
		},
		computed : {
			colorCSS : function () {
				if(this.type == 'spotlight'){
					return {
						"--arrows": colors['gray'][200],
						"--curr-page": colors['red'][500]
					}
				}
				if(this.type == "feature"){
					return {
						"--arrows": colors['gray'][700],
						"--curr-page": "#ffffff"
					}
				}
			},
			perPage() {
				return this.type == 'feature' ? 3 : 1
			},
			totalPages: function () {
				return Math.ceil(this.elements.length/this.perPage);
			},
			currentElements: function () {
				const startElement = this.currentPage * this.perPage
				const endElement = (this.currentPage + 1) * this.perPage
				return [...this.elements].slice(startElement, endElement)
			}
		},
		methods : {
			pageUp : function (event) {
				if(this.currentPage < this.totalPages-1) {
					this.currentPage++;
				}
			},
			pageDown : function (event) {
				if(this.currentPage > 0){
					this.currentPage--;
				}
			}
		}
	}


</script>

<style>
	.arrow{
		color: var(--arrows)
	}

	.not-curr-page {
		background-color: var(--arrows)
	}
	.curr-page {
		background-color: var(--curr-page)
	}
</style>
