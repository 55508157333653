<template>
	<button class="corner-button items-center w-[84px] h-[48px] transition-all" :style="colorCSS">
		<icon :size="icons[iconType]['size']" :name="icons[iconType]['name']" class="icon absolute top-0 bottom-0 my-auto right-s5"></icon>
	</button>
</template>

<script>

	import { colors } from '../../../config/build/carbon.mjs';

	const iconDefs = {
		modal:{
			size:'xs',
			name: 'plus'
		},
		link:{
			size:'xs',
			name: 'arrowhead-right'
		},
		close:{
			size:'xs',
			name: 'exit'
		}
	}

	const cornerBtnColors = {
		gray:{
			base: colors['gray'][900],
			icon: colors['gray'][100],
			hover: colors['red'][500],
			iconHover: "#FFFFFF"
		},
		white:{
			base: "#FFFFFF",
			icon: colors['gray'][500],
			hover: "#FFFFFF",
			iconHover: colors['gray'][500]
		},
		red:{
			base: colors['red'][500],
			icon: "#FFFFFF",
			hover: colors['gray'][900],
			iconHover: "#FFFFFF"
		}
	}

    export default {
		props: {
			iconType: String,
			color: String,
		},
		computed: {
			colorCSS () {
				const theme = cornerBtnColors[this.color]

				return {
					'--bg-color': theme['base'],
					'--icon-color': theme['icon'],
					'--hover-color': theme['hover'],
					'--icon-hover-color': theme['iconHover']
				}
			},
			icons () {
				return iconDefs;
			}
		}
	}
</script>

<style scoped>
    .corner-button{
		background-color: var(--bg-color);
		color: var(--icon-color)
    }

	.corner-button .icon{
		color:inherit;
	}


	.button-parent:hover .corner-button, .corner-button:hover{
		cursor:pointer;
		background-color: var(--hover-color);
		color: var(--icon-hover-color)
	}

</style>
