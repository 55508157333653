<script>
const buttonSizes = {
	base: {},
	small: {},
}

export default {
	props: {
		size: {
			type: String,
			validator: value => Object.keys(buttonSizes).includes(value)
		},
		iconLeft: String,
		iconRight: String,
	},
}
</script>


<template>
	<span
		class="inline-flex items-center"
		:class="[size === 'small' ? 'space-x-s2' : 'space-x-s3']"
	>
		<Icon
			v-if="iconLeft"
			:name="iconLeft"
			size="tiny"
			class="text-current pb-[1px]"
		/>

		<span :class="[size === 'small' ? 'ui-sm' : 'ui']"><slot /></span>

		<Icon
			v-if="iconRight"
			:name="iconRight"
			size="tiny"
			class="text-current pb-[1px]"
		/>
	</span>
</template>
